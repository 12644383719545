import React, { PureComponent } from 'react';
import ConnectHomePage from '../components/ConnectHomePage';

class IndexPage extends PureComponent {
    render() {
        return (
            <ConnectHomePage />
        );
    }
}

export default IndexPage;
