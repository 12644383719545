import React, { PureComponent } from 'react';
import OpenEarLogo from '../OpenEarLogo';
import './styles.scss';

class ConnectHomePage extends PureComponent {
    render() {
        return (
            <div className="connect-home-page">
                <a className="connect-home-page__link" href="https://openearmusic.com">
                    <OpenEarLogo fullColourLogo />
                </a>
            </div>
        );
    }
}

export default ConnectHomePage;
